import { formatDateTime } from '@/helpers/formatting';
import { jsPDF } from 'jspdf';
import autoTable from 'jspdf-autotable';

export const generatePdf = (columns, items, title) => {
  const doc = new jsPDF('landscape', 'px');

  const fileWidth = doc.internal.pageSize.getWidth();
  const alignImage = fileWidth / 2 - 25;
  const xOffset =
    (doc.internal.pageSize.getWidth() - doc.getTextWidth(title)) / 2;

  /*
   * Imagem centralizada no começo do arquivo
   */
  const img = new Image();
  img.src = require('@/assets/logo/logo.png');
  doc.addImage(img, 'PNG', alignImage, 5, 50, 27);

  /*
   * Linha superior do titulo
   */
  doc.setDrawColor(213, 209, 209);
  doc.line(0, 40, fileWidth, 40);

  /*
   * Titulo do relatório
   */
  doc.setTextColor('#5c036f');
  doc.text(title, xOffset, 54);

  /*
   * Linha inferior do titulo
   */
  doc.setDrawColor(213, 209, 209);
  doc.line(0, 60, fileWidth, 60);

  /*
   * Configurações para a tabela com paginação
   */
  autoTable(doc, {
    body: items,
    bodyStyles: {
      font: 'times',
      fontSize: 9,
      fontStyle: 'normal',
      valign: 'top'
    },
    columns,
    headStyles: {
      fillColor: [239, 230, 241],
      font: 'times',
      fontSize: 9,
      fontStyle: 'normal',
      textColor: [92, 3, 111]
    },
    margin: { horizontal: 5 },
    startY: 70,
    styles: { overflow: 'linebreak' },
    theme: 'striped',
    didDrawPage: function (data) {
      doc.setFontSize(10);
      doc.setTextColor('#5c036f');

      const fileSpacing = 5;
      const paginationText = `Página ${doc.internal.getNumberOfPages()}`;
      const pageSize = doc.internal.pageSize;
      const pageHeight = pageSize.height || pageSize.getHeight();
      const pageWidth =
        doc.internal.pageSize.getWidth() - doc.getTextWidth(paginationText);

      doc.text(
        `Data e hora da Impressão: ${formatDateTime(new Date())}`,
        data.settings.margin.left,
        pageHeight - fileSpacing
      );

      doc.text(
        pageWidth - fileSpacing,
        pageHeight - fileSpacing,
        paginationText
      );
    }
  });

  doc.save(`${title}.pdf`);
};
