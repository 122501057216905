import { HttpClient } from '@/services/http';

export class ReportService extends HttpClient {
  async getContracts(params) {
    const result = await this.get('v1/reports/contracts', params);
    return result;
  }

  async getContractTotalizer(params) {
    const result = await this.get('v1/reports/contracts/totalizer', params);
    return result;
  }

  async getDownloadXlsContractReport(params) {
    const result = await this.get('v1/reports/contracts/xls', params);
    return result;
  }

  async getExportDataContractReport(params) {
    const result = await this.get('v1/reports/contracts/export', params);
    return result;
  }

  async getInvoices(params) {
    const result = await this.get('v1/reports/invoices', params);
    return result;
  }

  async getExportXlsInvoiceReport(params) {
    const result = await this.get('v1/reports/invoices/xls', params);
    return result;
  }

  async getExportDataInvoiceRepot(params) {
    const result = await this.get('v1/reports/invoices/export', params);
    return result;
  }
}
